import React, { FunctionComponent, useEffect } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  List,
  Toolbar,
  Collapse,
  ListItemButton,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { styled } from '@mui/material/styles'
import { handleNavigationClick, isModuleVisible } from '../../../helpers/utils'
import TcreateLogo from '../../../../src/assets/images/logos/tcreate-logo.png'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ReactComponent as ContactIcon } from '../../../assets/images/icons/contact_icon.svg'
import { ReactComponent as EduboxIcon } from '../../../assets/images/icons/edubox.svg'
import PublicIcon from '@mui/icons-material/Public'
import PeopleIcon from '@mui/icons-material/People'
import EventNoteIcon from '@mui/icons-material/EventNote'
import SettingsIcon from '@mui/icons-material/Settings'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { history } from '../../../helpers/history'
import { useTranslation } from 'react-i18next'
import { ApplicationModule } from '../../../store/Config/types'
import MapIcon from '@mui/icons-material/Map'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import NoteIcon from '@mui/icons-material/Note'
import ConfigService from '../../../services/config.service'
import { errorHandler } from '../../../helpers/errorHandler'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import StoreIcon from '@mui/icons-material/Store'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import CategoryIcon from '@mui/icons-material/Category'
import InventoryIcon from '@mui/icons-material/Inventory'
import FactoryIcon from '@mui/icons-material/Factory'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import PercentIcon from '@mui/icons-material/Percent'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import RedeemIcon from '@mui/icons-material/Redeem'
import SummarizeIcon from '@mui/icons-material/Summarize'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import HomeIcon from '@mui/icons-material/Home'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#373739',
    color: '#DCDCDC',
    fill: '#DCDCDC',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '.active-path': {
      color: theme.colorsPalette.yellow.main,
      svg: {
        fill: theme.colorsPalette.yellow.main,
      },
    },
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
  '.tcreate-logo': {
    position: 'absolute',
    right: '93px',
  },
  '.hide-icon': {
    fill: theme.colorsPalette.yellow.main,
  },
  '.side-navbar-container': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    overflowX: 'hidden',
    '.side-navbar-menu-list': {
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgba(255,255,255, .4) rgba(255,255,255, .2)',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255,255,255, .2)',
        borderRadius: '5vw',
        transition: '3s',

        '&:hover': {
          backgroundColor: 'rgba(255,255,255, .4)',
        },
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(255,255,255, 0)',
        borderRadius: '5vw',
        transition: '3s',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, .2)',
        },
      },
    },
  },
  '.nav-footer-container': {
    overflow: 'hidden',
    color: theme.colorsPalette.gray.gray4,
    fontSize: '.875rem',
    '.nav-footer-txt': {
      '&_version': {
        margin: '10px 0px',
      },
      '&_www': {
        a: {
          marginLeft: '5px',
        },
      },
      a: {
        color: theme.colorsPalette.gray.gray4,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  '.contact-icon': {
    width: '24px',
    fill: theme.colorsPalette.orange.medium,
  },
  '.prevent-default-btn': {},
}))

type NavigationProps = {
  open: boolean
  handleDrawerClose: () => void
  currentUser: any
  applicationModules: ApplicationModule[]
}

const Navigation: FunctionComponent<NavigationProps> = ({
  open,
  handleDrawerClose,
  currentUser,
  applicationModules,
}) => {
  const VERSION = process.env.REACT_APP_VERSION
  const { t } = useTranslation()
  const [currentLocation, setCurrentLocation] = React.useState<string>(
    history.location.pathname,
  )
  const [openHelp, setOpenHelp] = React.useState(false)
  const [openUsers, setOpenUsers] = React.useState(false)
  const [openPlans, setOpenPlans] = React.useState(false)
  const [openStores, setOpenStores] = React.useState(false)
  const [openChallenges, setOpenChallenges] = React.useState(false)
  const [openResources, setOpenResources] = React.useState(false)
  const [openConfiguration, setOpenConfiguration] = React.useState(false)
  const [openLoyalty, setOpenLoyalty] = React.useState(false)
  const [apiVersion, setApiVersion] = React.useState('')

  const [currentOrganizationPath, setCurrentOrganizationPath] =
    React.useState('/')

  const [currentSettingsPath, setCurrentSettingsPath] = React.useState('/')

  const [currentStoresNotesPath, setCurrentStoresNotesPath] =
    React.useState('/')

  const [currentClientsPath, setCurrentClientsPath] = React.useState('/')

  const [currentUsersPath, setCurrentUsersPath] = React.useState('/')

  const [currentStoryPath, setCurrentStoryPath] = React.useState('/')

  const handleHelpClick = () => {
    setOpenHelp(!openHelp)
    setOpenUsers(false)
    setOpenPlans(false)
    setOpenStores(false)
    setOpenChallenges(false)
    setOpenConfiguration(false)
    setOpenResources(false)
    setOpenLoyalty(false)
  }

  const handleUsersClick = () => {
    setOpenUsers(!openUsers)
    setOpenHelp(false)
    setOpenPlans(false)
    setOpenStores(false)
    setOpenChallenges(false)
    setOpenConfiguration(false)
    setOpenResources(false)
    setOpenLoyalty(false)
  }

  const handlePlansClick = () => {
    setOpenPlans(!openPlans)
    setOpenHelp(false)
    setOpenUsers(false)
    setOpenStores(false)
    setOpenChallenges(false)
    setOpenConfiguration(false)
    setOpenResources(false)
    setOpenLoyalty(false)
  }

  const handleStoresClick = () => {
    setOpenStores(!openStores)
    setOpenHelp(false)
    setOpenUsers(false)
    setOpenPlans(false)
    setOpenChallenges(false)
    setOpenConfiguration(false)
    setOpenResources(false)
    setOpenLoyalty(false)
  }

  const handleChallengesClick = () => {
    setOpenChallenges(!openChallenges)
    setOpenHelp(false)
    setOpenPlans(false)
    setOpenStores(false)
    setOpenUsers(false)
    setOpenConfiguration(false)
    setOpenResources(false)
    setOpenLoyalty(false)
  }

  const handleConfigurationClick = () => {
    setOpenConfiguration(!openConfiguration)
    setOpenHelp(false)
    setOpenUsers(false)
    setOpenPlans(false)
    setOpenChallenges(false)
    setOpenStores(false)
    setOpenResources(false)
    setOpenLoyalty(false)
  }

  const handleResourcesClick = () => {
    setOpenResources(!openResources)
    setOpenConfiguration(false)
    setOpenHelp(false)
    setOpenUsers(false)
    setOpenPlans(false)
    setOpenChallenges(false)
    setOpenStores(false)
    setOpenLoyalty(false)
  }
  const handleLoyaltyClick = () => {
    setOpenLoyalty(!openLoyalty)
    setOpenHelp(false)
    setOpenPlans(false)
    setOpenStores(false)
    setOpenChallenges(false)
    setOpenConfiguration(false)
    setOpenResources(false)
    setOpenUsers(false)
  }

  useEffect(() => {
    history.listen(() => {
      setCurrentLocation(history.location.pathname)
    })
  }, [])

  useEffect(() => {
    if (isModuleVisible('COMPANY', applicationModules, currentUser)) {
      setCurrentOrganizationPath('organization/companies')
    } else if (isModuleVisible('REGION', applicationModules, currentUser)) {
      setCurrentOrganizationPath('organization/regions')
    } else {
      setCurrentOrganizationPath('/')
    }

    if (isModuleVisible('GENERAL_SETTINGS', applicationModules, currentUser)) {
      setCurrentSettingsPath('settings/general-settings')
    } else if (
      isModuleVisible('SYSTEM_SETTINGS', applicationModules, currentUser)
    ) {
      setCurrentSettingsPath('settings/system-settings')
    } else if (
      isModuleVisible('MESSENGER_SETTINGS', applicationModules, currentUser)
    ) {
      setCurrentSettingsPath('settings/chatbox-settings')
    } else if (
      isModuleVisible('APPLICATION_SETTINGS', applicationModules, currentUser)
    ) {
      setCurrentSettingsPath('settings/application-settings')
    } else {
      setCurrentSettingsPath('/')
    }

    if (isModuleVisible('NOTES', applicationModules, currentUser)) {
      setCurrentStoresNotesPath('stores-notes/notes')
    } else {
      setCurrentStoresNotesPath('/')
    }

    if (isModuleVisible('STORY_LEVELS', applicationModules, currentUser)) {
      setCurrentStoryPath('story/story-levels')
    } else {
      setCurrentStoryPath('/')
    }

    if (isModuleVisible('STORE', applicationModules, currentUser)) {
      setCurrentClientsPath('clients/clients-list')
    } else if (
      isModuleVisible('STORE_PERIODS', applicationModules, currentUser)
    ) {
      setCurrentClientsPath('clients/clients-assignments')
    } else if (
      isModuleVisible('STORE_PARAMETERS', applicationModules, currentUser)
    ) {
      setCurrentClientsPath('clients/clients-parameters')
    } else if (
      isModuleVisible('STORE_IMPORT', applicationModules, currentUser)
    ) {
      setCurrentClientsPath('clients/clients-imports')
    } else {
      setCurrentClientsPath('/')
    }

    if (isModuleVisible('USER', applicationModules, currentUser)) {
      setCurrentUsersPath('users/ph')
    } else if (isModuleVisible('ADMIN', applicationModules, currentUser)) {
      setCurrentUsersPath('users/company-admins')
    } else if (isModuleVisible('ASM', applicationModules, currentUser)) {
      setCurrentUsersPath('users/asm')
    } else if (isModuleVisible('KAM', applicationModules, currentUser)) {
      setCurrentUsersPath('users/kam')
    } else {
      setCurrentUsersPath('/')
    }
  }, [applicationModules, currentUser])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ApiVersionResponse = await ConfigService.getApiVersion()

        if (ApiVersionResponse.data.apiVersion) {
          setApiVersion(ApiVersionResponse.data.apiVersion)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    fetchData()
  }, [t])

  const renderMenuItem = (
    routeName: string,
    translation: string,
    icon: React.ReactNode,
    isRoot = false,
    isSubItem = false,
    possibleRoutes?: string[],
  ) => {
    const routes = possibleRoutes ? possibleRoutes : []
    const routeRegex = new RegExp(`^/${routeName}(|/|/(.*))$`, 'g')

    return (
      <ListItem
        className={
          routeRegex.test(currentLocation) ||
          currentLocation === (isRoot ? '/' : '') ||
          routes.includes(currentLocation)
            ? 'active-path'
            : ''
        }
        onClick={() => handleNavigationClick(routeName)}
        sx={{ pl: isSubItem ? 4 : 2, cursor: 'pointer' }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={translation} />
      </ListItem>
    )
  }

  const renderListItems = () => {
    return (
      <div>
        {renderMenuItem(
          'home',
          t('navigation.home'),
          <HomeIcon color="secondary" />,
          true,
        )}
        {isModuleVisible('DASHBOARD', applicationModules, currentUser) &&
          renderMenuItem(
            'dashboard',
            t('navigation.dashboard'),
            <DashboardIcon color="secondary" />,
          )}
        {isModuleVisible('LEADERBOARD', applicationModules, currentUser) &&
          renderMenuItem(
            'game',
            t('navigation.game'),
            <MapIcon color="secondary" />,
          )}
        {(isModuleVisible('USER', applicationModules, currentUser) ||
          isModuleVisible('ADMIN', applicationModules, currentUser) ||
          isModuleVisible('ASM', applicationModules, currentUser) ||
          isModuleVisible('KAM', applicationModules, currentUser) ||
          isModuleVisible('USER_PERIOD', applicationModules, currentUser) ||
          isModuleVisible(
            'GAME_OPERATION_LIST',
            applicationModules,
            currentUser,
          ) ||
          isModuleVisible('TEAM_POINTS', applicationModules, currentUser) ||
          isModuleVisible('GAME_AWARDS', applicationModules, currentUser)) && (
          <>
            <ListItemButton onClick={handleUsersClick}>
              <ListItemIcon>
                <PeopleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('navigation.users.users')} />
              {openUsers ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openUsers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(isModuleVisible('USER', applicationModules, currentUser) ||
                  isModuleVisible('ADMIN', applicationModules, currentUser) ||
                  isModuleVisible('ASM', applicationModules, currentUser) ||
                  isModuleVisible('KAM', applicationModules, currentUser) ||
                  isModuleVisible(
                    'USER_PERIOD',
                    applicationModules,
                    currentUser,
                  )) &&
                  renderMenuItem(
                    currentUsersPath,
                    t('navigation.users.managament'),
                    <RecentActorsIcon color="secondary" />,
                    true,
                    true,
                    //array for active navigation item color when inner page navigation is in use (many possible paths for one navigation item)
                    [
                      '/users/ph',
                      '/users/company-admins',
                      '/users/asm',
                      '/users/kam',
                      '/users/imports',
                    ],
                  )}
                {isModuleVisible(
                  'GAME_OPERATION_LIST',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'operations',
                    t('navigation.users.operations'),
                    <FormatListBulletedIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'TEAM_POINTS',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'team-points',
                    t('navigation.users.teamPoints'),
                    <GroupAddIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'GAME_AWARDS',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'awards',
                    t('navigation.users.awards'),
                    <RedeemIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'USER_PERIOD',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'users-periods',
                    t('navigation.users.usersPeriods'),
                    <EmojiEventsIcon color="secondary" />,
                    false,
                    true,
                  )}
              </List>
            </Collapse>
          </>
        )}
        {(isModuleVisible('STORE', applicationModules, currentUser) ||
          isModuleVisible('STORE_PERIODS', applicationModules, currentUser) ||
          isModuleVisible(
            'STORE_PARAMETERS',
            applicationModules,
            currentUser,
          ) ||
          isModuleVisible('STORE_IMPORT', applicationModules, currentUser) ||
          isModuleVisible('STORE_ORDERS', applicationModules, currentUser) ||
          isModuleVisible(
            'STORE_DISCOUNTS',
            applicationModules,
            currentUser,
          )) && (
          <>
            <ListItemButton onClick={handleStoresClick}>
              <ListItemIcon>
                <ApartmentIcon color="secondary" />,
              </ListItemIcon>
              <ListItemText primary={t('navigation.stores.clients')} />
              {openStores ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openStores} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(isModuleVisible('STORE', applicationModules, currentUser) ||
                  isModuleVisible(
                    'STORE_PERIODS',
                    applicationModules,
                    currentUser,
                  )) &&
                  renderMenuItem(
                    `${currentClientsPath}`,
                    t('navigation.stores.manage'),
                    <SettingsApplicationsIcon color="secondary" />,
                    true,
                    true,
                    //array for active navigation item color when inner page navigation is in use (many possible paths for one navigation item)
                    [
                      '/clients/clients-list',
                      '/clients/clients-assignments',
                      '/clients/clients-parameters',
                      '/clients/clients-imports',
                    ],
                  )}
                {isModuleVisible(
                  'STORE_ORDERS',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'orders',
                    t('navigation.stores.orders'),
                    <ReceiptLongIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'STORE_DISCOUNTS',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'stores-discounts',
                    t('navigation.stores.discounts'),
                    <PercentIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible('NOTES', applicationModules, currentUser) &&
                  renderMenuItem(
                    `${currentStoresNotesPath}`,
                    t('navigation.stores.notes'),
                    <NoteIcon color="secondary" />,
                    true,
                    true,
                    //array for active navigation item color when inner page navigation is in use (many possible paths for one navigation item)
                    [
                      '/stores-notes/notes',
                      '/stores-notes/note-groups',
                      '/stores-notes/note-attachments',
                    ],
                  )}
              </List>
            </Collapse>
          </>
        )}
        {(isModuleVisible('PLAN', applicationModules, currentUser) ||
          isModuleVisible('STORE_PLAN', applicationModules, currentUser) ||
          isModuleVisible('USER_PLAN', applicationModules, currentUser) ||
          isModuleVisible('MODIFY_PLAN', applicationModules, currentUser)) && (
          <>
            <ListItemButton onClick={handlePlansClick}>
              <ListItemIcon>
                <EventNoteIcon color="secondary" />,
              </ListItemIcon>
              <ListItemText primary={t('navigation.plans.plans')} />
              {openPlans ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openPlans} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isModuleVisible('PLAN', applicationModules, currentUser) &&
                  renderMenuItem(
                    'plans-manage',
                    t('navigation.plans.manage'),
                    <SettingsApplicationsIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'USER_PLAN',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'plans/users',
                    t('navigation.plans.users'),
                    <QueryStatsIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'STORE_PLAN',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'plans/stores',
                    t('navigation.plans.stores'),
                    <StoreIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'MODIFY_PLAN',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'user-plans',
                    t('navigation.plans.modify'),
                    <EventRepeatIcon color="secondary" />,
                    false,
                    true,
                  )}
              </List>
            </Collapse>
          </>
        )}
        {(isModuleVisible('CHALLENGE', applicationModules, currentUser) ||
          isModuleVisible('USER_CHALLENGE', applicationModules, currentUser) ||
          isModuleVisible(
            'STORE_CHALLENGE',
            applicationModules,
            currentUser,
          )) && (
          <>
            <ListItemButton onClick={handleChallengesClick}>
              <ListItemIcon>
                <NoteAltIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('navigation.challenges.challenges')} />
              {openChallenges ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openChallenges} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isModuleVisible(
                  'CHALLENGE',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'challenges',
                    t('navigation.challenges.manage'),
                    <SettingsApplicationsIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'USER_CHALLENGE',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'challenges-users',
                    t('navigation.challenges.users'),
                    <QueryStatsIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'STORE_CHALLENGE',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'challenges-stores',
                    t('navigation.challenges.stores'),
                    <StoreIcon color="secondary" />,
                    false,
                    true,
                  )}
              </List>
            </Collapse>
          </>
        )}
        {isModuleVisible('EDU_BOX', applicationModules, currentUser) &&
          renderMenuItem('edubox', t('navigation.edubox'), <EduboxIcon />)}
        {isModuleVisible('INFO_BOX', applicationModules, currentUser) &&
          renderMenuItem(
            'actions',
            t('navigation.actions'),
            <FindInPageIcon color="secondary" />,
          )}
        {(isModuleVisible('LOYALTY_USER', applicationModules, currentUser) ||
          isModuleVisible('LOYALTY_PLAN', applicationModules, currentUser) ||
          isModuleVisible(
            'LOYALTY_PROTOCOL',
            applicationModules,
            currentUser,
          ) ||
          isModuleVisible('CONFIG_LOYALTY', applicationModules, currentUser) ||
          isModuleVisible(
            'LOYALTY_OPERATION_LIST',
            applicationModules,
            currentUser,
          ) ||
          isModuleVisible('LOYALTY_AWARDS', applicationModules, currentUser) ||
          isModuleVisible(
            'PARTICIPANT_PLAN',
            applicationModules,
            currentUser,
          ) ||
          isModuleVisible(
            'LOYALTY_PERIOD',
            applicationModules,
            currentUser,
          )) && (
          <>
            <ListItemButton onClick={handleLoyaltyClick}>
              <ListItemIcon>
                <MilitaryTechIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('navigation.loyalty.loyalty')} />
              {openLoyalty ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openLoyalty} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isModuleVisible(
                  'LOYALTY_USER',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-users/participant',
                    t('navigation.loyalty.loyaltyUsers'),
                    <PeopleIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'LOYALTY_PLAN',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-plans',
                    t('navigation.loyalty.loyaltyPlans'),
                    <EventNoteIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'LOYALTY_PROTOCOL',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-protocols',
                    t('navigation.loyalty.loyaltyProtocols'),
                    <FactCheckIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'CONFIG_LOYALTY',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'configuration-loyalty',
                    t('navigation.loyalty.settings'),
                    <PhonelinkSetupIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'LOYALTY_OPERATION_LIST',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-operations',
                    t('navigation.loyalty.loyaltyOperations'),
                    <FormatListBulletedIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'LOYALTY_AWARDS',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-awards',
                    t('navigation.loyalty.loyaltyAwards'),
                    <RedeemIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'PARTICIPANT_PLAN',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-participant-plans',
                    t('navigation.loyalty.loyaltyParticipantPlans'),
                    <SummarizeIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'LOYALTY_PERIOD',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'loyalty-periods',
                    t('navigation.loyalty.loyaltyPeriods'),
                    <CalendarMonthIcon color="secondary" />,
                    false,
                    true,
                  )}
              </List>
            </Collapse>
          </>
        )}
        {(isModuleVisible('PRODUCT', applicationModules, currentUser) ||
          isModuleVisible('MANUFACTURER', applicationModules, currentUser) ||
          isModuleVisible('IMAGE', applicationModules, currentUser) ||
          isModuleVisible('STORY_LEVELS', applicationModules, currentUser)) && (
          <>
            <ListItemButton onClick={handleResourcesClick}>
              <ListItemIcon>
                <CategoryIcon color="secondary" />,
              </ListItemIcon>
              <ListItemText primary={t('navigation.resources.resources')} />
              {openResources ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openResources} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isModuleVisible('PRODUCT', applicationModules, currentUser) &&
                  renderMenuItem(
                    'products',
                    t('navigation.resources.products'),
                    <InventoryIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'MANUFACTURER',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    'manufacturers',
                    t('navigation.resources.manufacturers'),
                    <FactoryIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible('IMAGE', applicationModules, currentUser) &&
                  renderMenuItem(
                    'images',
                    t('navigation.resources.images'),
                    <BurstModeIcon color="secondary" />,
                    false,
                    true,
                  )}
                {isModuleVisible(
                  'STORY_LEVELS',
                  applicationModules,
                  currentUser,
                ) &&
                  renderMenuItem(
                    `${currentStoryPath}`,
                    t('navigation.resources.story'),
                    <ImportantDevicesIcon color="secondary" />,
                    true,
                    true,
                    ['/story/story-levels'],
                  )}
              </List>
            </Collapse>
          </>
        )}
        {(isModuleVisible('PERIOD', applicationModules, currentUser) ||
          isModuleVisible(
            'GENERAL_SETTINGS',
            applicationModules,
            currentUser,
          ) ||
          isModuleVisible('SYSTEM_SETTINGS', applicationModules, currentUser) ||
          isModuleVisible('COMPANY', applicationModules, currentUser) ||
          isModuleVisible('REGION', applicationModules, currentUser)) && (
          <>
            <ListItemButton onClick={handleConfigurationClick}>
              <ListItemIcon>
                <SettingsIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={t('navigation.configuration.configuration')}
              />
              {openConfiguration ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openConfiguration} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isModuleVisible('PERIOD', applicationModules, currentUser) &&
                  renderMenuItem(
                    'periods',
                    t('navigation.configuration.periods'),
                    <CalendarMonthIcon color="secondary" />,
                    false,
                    true,
                  )}
                {(isModuleVisible(
                  'GENERAL_SETTINGS',
                  applicationModules,
                  currentUser,
                ) ||
                  isModuleVisible(
                    'SYSTEM_SETTINGS',
                    applicationModules,
                    currentUser,
                  )) &&
                  renderMenuItem(
                    `${currentSettingsPath}`,
                    t('navigation.configuration.settings'),
                    <PhonelinkSetupIcon color="secondary" />,
                    false,
                    true,
                    //array for active navigation item color when inner page navigation is in use (many possible paths for one navigation item)
                    [
                      '/settings/general-settings',
                      '/settings/system-settings',
                      '/settings/chatbox-settings',
                      '/settings/chatbox-settings/conversation-group/:challangeId',
                      '/settings/application-settings',
                    ],
                  )}
                {(isModuleVisible('COMPANY', applicationModules, currentUser) ||
                  isModuleVisible('REGION', applicationModules, currentUser)) &&
                  renderMenuItem(
                    `${currentOrganizationPath}`,
                    t('navigation.configuration.organization'),
                    <PublicIcon color="secondary" />,
                    true,
                    true,
                    //array for active navigation item color when inner page navigation is in use (many possible paths for one navigation item)
                    ['/organization/companies', '/organization/regions'],
                  )}
              </List>
            </Collapse>
          </>
        )}
        {isModuleVisible('MANUAL', applicationModules, currentUser) && (
          <>
            <ListItemButton onClick={handleHelpClick}>
              <ListItemIcon>
                <ContactSupportIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('navigation.help.help')} />
              {openHelp ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openHelp} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component="a"
                  sx={{ pl: 4 }}
                  href={`${window.location.origin}/docs/instrukcja.pdf`}
                  target="_blank"
                >
                  <ListItemIcon>
                    <PictureAsPdfIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.help.instruction')} />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
      </div>
    )
  }

  return (
    <Drawer variant="permanent" open={open} sx={{ height: '100vh' }}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <img className="tcreate-logo" src={TcreateLogo} alt="" />
          <ChevronLeftIcon className="hide-icon" />
        </IconButton>
      </Toolbar>
      <Divider />
      <div className="side-navbar-container">
        <List
          className="side-navbar-menu-list"
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          {renderListItems()}
        </List>
      </div>
      <ListItem>
        <ListItemIcon>
          <ContactIcon className="contact-icon" />
        </ListItemIcon>
        <div className="nav-footer-container">
          <div className="nav-footer-txt">
            <div className="nav-footer-txt_www">
              Copyright
              <a
                href="https://tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                tcreate.pl
              </a>
            </div>
            <div className="nav-footer-txt_phone">+48 512 802 802</div>
            <div className="nav-footer-txt_mail">
              <a
                href="mailto:support@tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@tcreate.pl
              </a>
            </div>
            <small
              style={{
                opacity: 0.4,
              }}
            >
              api: {apiVersion} | web: {VERSION}
            </small>
          </div>
        </div>
      </ListItem>
    </Drawer>
  )
}

export default Navigation
